export const FEDOPS_WIDGET_APP_NAME = 'bookings-widget-phase-one';

export const FEDOPS_MAIN_PAGE_NAME = 'bookings-main-page';

export const SENTRY_BOOKINGS_WIDGET_DSN =
  'https://87168827af6c40f29992e0213f5c86b4@sentry.io/1401356';

export const FEDOPS_MAIN_DESKTOP_BOOKINGS_APPLICATION_NAME =
  'scheduler-uou-desktop';

export const FEDOPS_MAIN_MOBILE_BOOKINGS_APPLICATION_NAME =
  'scheduler-uou-mobile';

export const FEDOPS_DEEP_LINK_NAVIGATION_INTERACTION =
  'from-list-widget-to-offering-page';
